<template>
  <div id="zones" class="tab-pane active add-new-visitor-form-container">
    <div class="content">
      <div class="top-button-box">
        <router-link :to="{ name: 'addDevice' }" class="blue-link-button">{{
          $ml.get("devices.addDevice")
        }}</router-link>
      </div>
      <datatable
        :fields="fields"
        :rows="devices"
        :rowstyle="styleRow"
        :dblclick="edit"
        >{{ $ml.get("devices.notDevices") }}</datatable
      >
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
